import React, { Fragment, useEffect } from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

import Hero from "layout/Hero"
import Container from "layout/Container"
import { animate } from "services/animations"

const requirements = [
  ["Selected Pfizer medicines only", "Prescribed quantities only"],
  ["Viatris registered employees and dependents are eligible"],
  [
    "Prescription issued within the year including doctor’s PRC license number",
    "Viatris Employee ID",
  ],
  ["Cashless for company covered medicines"],
]

const ViatrisMechanics = () => {
  useEffect(() => {
    animate({
      x: -100,
      type: "from",
      isScrollTrigger: true,
      target: ".fade-from-left-mechanics",
      animateOn: ".animate-trigger-mechanics",
    })
  }, [])
  const data = useStaticQuery(graphql`
    {
      eligibility: file(
        relativePath: { eq: "mechanics/reqts__eligibility.png" }
      ) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      prescriber: file(
        relativePath: { eq: "mechanics/reqts__prescriber.png" }
      ) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      documents: file(relativePath: { eq: "mechanics/reqts__documents.png" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      cashout: file(relativePath: { eq: "mechanics/reqts__cashout.png" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      coveredMeds: file(
        relativePath: { eq: "mechanics/reqts__coveredMeds.png" }
      ) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const icons = [
    data.coveredMeds.childImageSharp.fixed,
    data.eligibility.childImageSharp.fixed,
    data.documents.childImageSharp.fixed,
    data.cashout.childImageSharp.fixed,
  ]

  const iconLabel = [
    "Covered Medicines",
    "Eligibility",
    "Required documents",
    "Payment Process",
  ]

  return (
    <Fragment>
      {/* has background light not working? */}
      <Hero heroClass="has-background-light mt-5">
        <center>
          <p className="has-text-grey is-size-4 mb-1">Ready to order?</p>
          <p className="title has-text-primary is-4 pb-1 is-size-2">
            MECHANICS
          </p>
        </center>
      </Hero>

      <Container isCentered>
        {requirements.map((requirement, index) => {
          return (
            <div
              className="columns is-mobile is-vcentered fade-from-left-mechanics"
              key={index}
            >
              <div className="column is-2 mb-0 px-0-mobile">
                <center>
                  <Img fixed={icons[index]} style={{ width: 50 }} />

                  <div className="is-size-7">{iconLabel[index]}</div>
                </center>
              </div>
              <div className="column mb-0">
                <article className="message is-warning">
                  <div className="message-body is-warning-dark p-1">
                    {typeof requirement === "string" ? (
                      `• ${requirement}`
                    ) : (
                      <Fragment>
                        {requirement.map((item) => (
                          <Fragment>
                            {`• ${item}`}
                            <br />
                          </Fragment>
                        ))}
                      </Fragment>
                    )}
                  </div>
                </article>
              </div>
              <section className="animate-trigger-mechanics"></section>
            </div>
          )
        })}
      </Container>
    </Fragment>
  )
}

export default ViatrisMechanics
