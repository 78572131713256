import React, { useEffect } from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"

import Hero from "layout/Hero"
import Button from "elements/Button"
import Container from "layout/Container"
import Brand from "../../Elements/Brand"

import { animate } from "services/animations"
import styles from "../utils/staticPages.module.scss"

const HomeBanner = () => {
  useEffect(() => {
    animate({
      x: -100,
      type: "from",
      target: ".fade-from-left",
      duration: 1,
      stagger: 0.2,
    })
  }, [])
  const data = useStaticQuery(graphql`
    {
      heart: file(relativePath: { eq: "index__hero--heart.jpg" }) {
        childImageSharp {
          fluid(
            duotone: { highlight: "#ffffff", shadow: "#0d6d6e" }
            toFormat: PNG
            quality: 90
            maxWidth: 1000
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const heart = data.heart.childImageSharp.fluid.src

  return (
    <Hero
      size="medium"
      background={{
        image: heart,
        position: "center",
      }}
    >
      <Container isCentered desktop={10} fullhd={8}>
        <h1 className="title is-size-1 has-text-primary fade-from-left">
          <Brand /> <br /> Medicine Program
        </h1>

        <p className="subtitle is-size-5 pb-3 pt-1 fade-from-left">
          Medicine benefits for <Brand /> employees{" "}
          <br className="is-hidden-mobile" />
        </p>

        <div className={(styles["buttons"], "fade-from-left")}>
          <Button
            color="primary"
            size="medium"
            onClick={() => navigate("/epharmacy/mechanics")}
          >
            Order Medicines
          </Button>
        </div>
      </Container>
    </Hero>
  )
}

export default HomeBanner
