import React, { Fragment } from "react"
import Img from "gatsby-image"
import classNames from "classnames"

import styles from "../utils/staticPages.module.scss"

const HomeMediaContainer = ({ title, description, image, customClassName }) => {
  return (
    <Fragment>
      <div
        className={`column mb-3-mobile has-text-centered ${customClassName}`}
      >
        <div className={classNames(styles["home__image"])}>
          <Img fixed={image} />
        </div>
        <h5 className="mt-1 mb-2-mobile has-text-centered has-text-primary">
          {title}
        </h5>
        <p className="mx-3-mobile is-size-6 has-text-centered">{description}</p>
      </div>
    </Fragment>
  )
}

export default HomeMediaContainer
