import React, { Fragment, useEffect } from "react"

import Hero from "layout/Hero"
import Container from "layout/Container"
import HomeMediaContainer from "./HomeMediaContainer"
import HomeProcessArrow from "./HomeProcessArrow"
import HomeSearch from "../../Search/HomeSearch"

import useHomeImages from "../hooks/useHomeImages"
import howProgramWorks from "../utils/howProgramWorks.json"
import { animate } from "services/animations"

const HomeProcess = () => {
  useEffect(() => {
    animate({
      x: -100,
      type: "from",
      isScrollTrigger: true,
      target: ".fade-from-left-process",
      animateOn: ".animate-trigger-process",
    })
  }, [])

  const data = useHomeImages()
  const arrow = data.arrow.childImageSharp.fixed

  return (
    <Hero size="small">
      <Container isCentered desktop={10} fullhd={8}>
        <HomeSearch />
        <h2 className="my-3 has-text-centered has-text-primary">
          HOW IT WORKS
        </h2>
        <section className="animate-trigger-process"></section>
        <div className="columns is-centered mb-3">
          {howProgramWorks.map((item, index) => (
            <Fragment>
              <HomeMediaContainer
                title={item?.title}
                description={item?.description}
                image={data[item?.imageName]?.childImageSharp?.fixed}
                customClassName="fade-from-left-process"
              />
              {index < howProgramWorks?.length - 1 && (
                <HomeProcessArrow
                  arrowImage={arrow}
                  customClassName="fade-from-left-process"
                />
              )}
            </Fragment>
          ))}
        </div>
        <section className="animate-trigger-process"></section>
      </Container>
    </Hero>
  )
}

export default HomeProcess
