import React, { useState } from "react"
import { useStaticQuery } from "gatsby"

import Search from "./Search"
import Hero from "layout/Hero"
import Container from "layout/Container"
import MoleculeCoveredMessage from "./SearchFormulary/MoleculeCoveredMessage"

import { useEpharmacyProducts } from "../Epharmacy/hooks/useEpharmacyProducts"

const HomeSearch = () => {
  const epharmacyData = useEpharmacyProducts()
  const [selectedItem, setSelectedItem] = useState({})
  const [coveredItems, setCoveredItems] = useState([])

  let dataMap = epharmacyData.map((node) => node)

  const data = useStaticQuery(graphql`
    {
      successIcon: file(relativePath: { eq: "icons/fmc__check.png" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      formulary: file(relativePath: { eq: "icons/fmc__formulary.png" }) {
        childImageSharp {
          fixed(width: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const successIcon = data.successIcon.childImageSharp.fixed

  const handleOnSelect = async (selectedItem) => {
    setSelectedItem(selectedItem)
    setCoveredItems(
      dataMap.filter((item) => {
        return item?.drugName === selectedItem.drugName
      })
    )
  }

  return (
    <Hero size="small">
      <Container isCentered fullhd={10} desktop={10}>
        <div className="mb-4">
          <h3
            className="mt-3 has-text-centered has-text-primary"
            id="search-medicines"
          >
            What medicines are included in the formulary?
          </h3>

          <p className="mb-2">
            Search for your medicines with the branded or generic name. Only
            medicines you can find are covered in the program. Generics will be
            dispensed when available.
          </p>
          <Search
            placeholder="Metformin"
            withIcon={true}
            onSelect={handleOnSelect}
            dataSource={{
              type: "graphql",
              data: dataMap,
              lookupKeys: ["brand", "molecule"],
            }}
          />
          <MoleculeCoveredMessage
            selectedMedicine={selectedItem}
            successIcon={successIcon}
            coveredItems={coveredItems}
          />
        </div>
      </Container>
    </Hero>
  )
}

export default HomeSearch
