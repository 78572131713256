import React from "react"
import classNames from "classnames"
import Img from "gatsby-image"

import CoveredMedicinesList from "./CoveredMedicinesList"

import styles from "../utils/search.module.scss"

const MoleculeCoveredMessage = ({
  selectedMedicine,
  successIcon,
  coveredItems,
  flow,
}) => {
  if (flow === "request") return null
  if (selectedMedicine?.molecule)
    return (
      <article
        className={classNames(
          "message p-1 mt-1 is-success",
          styles["searchResult__coveredMedicines"]
        )}
      >
        <section className="media">
          <figure className="image is-64x64 is-32x32-mobile ml-0 mt-0 mr-1">
            <Img fixed={successIcon} />
          </figure>
          <div className="media-content">
            <div className="is-size-6">
              <span className="has-text-weight-bold">
                {selectedMedicine.molecule}
              </span>{" "}
              is covered by our formulary.
            </div>
            <div>
              <div className="my-1">FlexMed covers:</div>
              <CoveredMedicinesList coveredMedicines={coveredItems} />
            </div>
          </div>
        </section>
      </article>
    )
  return null
}

export default MoleculeCoveredMessage
