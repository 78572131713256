import { useStaticQuery, graphql } from "gatsby"

const useHomeImages = () => {
  const data = useStaticQuery(graphql`
    {
      upload: file(relativePath: { eq: "home/landing_upload--teal.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      search: file(relativePath: { eq: "home/landing_search--teal.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      receive: file(relativePath: { eq: "home/landing_receive--teal.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      arrow: file(relativePath: { eq: "process_triangle--grey.png" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return data
}

export default useHomeImages